import flatten from 'lodash/flatten';
import map from 'lodash/map';
import find from 'lodash/find';
import some from 'lodash/some';

export default {
  methods: {
    isRequired(field) {
      if (field.isRequired && !field.requiredIfField) {
        return true;
      }

      const flattenedSteps = flatten(map(this.data.areas, (i) => i.fields));
      const f = find(flattenedSteps, {
        id: field.requiredIfField
      });
      if (!f) return false;
      if (f.fieldType === 1) {
        return f.answer === field.requiredIfFieldOptions[0];
      } else if (f.fieldType === 2) {
        return some(
          f.answers,
          (answer) =>
            answer &&
            (field.requiredIfFieldOptions.indexOf(answer) >= 0 ||
              field.requiredIfFieldOptions.indexOf(answer.toString()) >= 0)
        );
      }
      return false;
    },
    getDashedFieldName(value) {
      return this.$options.filters.cleanSpecialChars(this.$options.filters.dash(value));
    }
  }
};
