<template>
  <div class="form-input">
    <!-- start normal input fields -->
    <template v-if="field.fieldType === 1 && field.validator !== 5 && field.validator !== 6 && field.validator !== 10">
      <template v-if="!field.type || field.type === 'text'">
        <masked-input
          v-if="field.mask"
          :mask="field.mask"
          type="text"
          v-validate="field.validate"
          :name="name"
          :maxlength="maxLength"
          class="form-control input"
          :placeholder="field.placeholderText"
          :value="value"
          @input="onInput"
          :readonly="readonly"
          :class="{ 'text-danger': errors.has(field.fieldName) }"
        />

        <input
          v-if="!field.mask"
          type="text"
          v-validate="field.validate"
          :name="name"
          :maxlength="maxLength"
          class="form-control input"
          :placeholder="field.placeholderText"
          :value="value"
          @input="onInput"
          :readonly="readonly"
          :class="{ 'text-danger': errors.has(field.fieldName) }"
        />
      </template>
      <input
        v-if="field.type === 'email'"
        type="email"
        v-validate="field.validate"
        :name="name"
        :maxlength="maxLength"
        class="form-control input"
        :placeholder="field.placeholderText"
        :value="value"
        @input="onInput"
        :readonly="readonly"
        :class="{ 'text-danger': errors.has(field.fieldName) }"
      />

      <textarea
        v-if="field.type === 'textarea'"
        v-validate="field.validate"
        :name="name"
        :maxlength="maxLength"
        class="form-control input"
        :placeholder="field.placeholderText"
        :value="value"
        @input="onInput"
        :readonly="readonly"
        :class="{ 'text-danger': errors.has(field.fieldName) }"
      />
    </template>

    <!-- Belgian City / Zip Typeahead -->
    <template v-if="field.fieldType === 1 && (field.validator === 5 || field.validator === 6)">
      <typeahead
        autocomplete="nope"
        :placeholder="field.placeholderText"
        :readonly="readonly"
        :data="field.validator == 5 ? zipData : cityData"
        v-validate="field.validate"
        :name="name"
        :value="value"
        @input="onInput"
      ></typeahead>
    </template>

    <!-- Country AutoComplete -->
    <template v-if="field.fieldType === 1 && field.validator === 10">
      <el-autocomplete
        class="inline-input"
        v-model="field.answer"
        :fetch-suggestions="querySearch"
        :placeholder="field.fieldName | dash | cleanSpecialChars"
        v-if="field.itemType === 1"
        :name="name"
        :trigger-on-focus="false"
        v-validate="{ required: required, country: this.countryResponse }"
        :required="required"
        autocomplete="nope"
      >
      </el-autocomplete>
    </template>

    <!-- end normal input fields -->
    <!-- start select fields -->
    <template v-if="field.fieldType === 2">
      <multiselect
        v-model="field.answers"
        :options="field.options"
        track-by="name"
        label="name"
        :searchable="false"
        :multiple="field.chooseMultipleOptions"
        :close-on-select="!field.chooseMultipleOptions"
        :clear-on-select="false"
        :hide-selected="true"
        :name="name"
        v-validate="field.validate"
        :readonly="readonly"
        :deselect-label="required ? 'Can\'t remove this value' : 'Press Enter to remove'"
        :allow-empty="!required"
        :selectLabel="$t('form.selectLabel')"
        :selectedLabel="$t('form.selectedLabel')"
        :deselectLabel="$t('form.deselectLabel')"
      ></multiselect>
    </template>
    <!-- end select fields -->
    <!-- start file upload-->
    <template v-if="field.fieldType === 3">
      <el-upload
        v-if="!field.fileName"
        :auto-upload="false"
        action
        :limit="1"
        :name="field.fileName"
        :multiple="false"
        :on-change="handleUploadChange"
        :on-remove="handleUploadRemove"
        list-type="picture"
      >
        <el-button
          v-if="!data.isClosed && (!field.fileList || !field.fileList.length)"
          size="small"
          type="primary"
          class="text-uppercase"
          >{{ 'form.clickToUpload' | translate }}</el-button
        >
        <!-- <div slot="tip" class="el-upload__tip">jpg/png files with a size less than 500kb</div> -->
      </el-upload>
      <div v-if="field.fileName" style="display: grid" :class="{ 'pointer-none': data.isClosed }">
        <ul class="el-upload-list el-upload-list--picture">
          <li tabindex="0" class="el-upload-list__item is-ready">
            <a class="el-upload-list__item-name">
              <i
                :class="{
                  'el-icon-picture': /\.(gif|jpg|jpeg|tiff|png)$/i.test(field.fileName),
                  'el-icon-document': /\.(gif|jpg|jpeg|tiff|png)$/i.test(field.fileName)
                }"
              ></i>
              {{ field.fileName }}
            </a>
            <label class="el-upload-list__item-status-label">
              <i class="el-icon-upload-success el-icon-check"></i>
            </label>
            <i class="el-icon-close" @click="field.fileName = null"></i>
            <i class="el-icon-close-tip">delete</i>
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import { Button, Upload, Autocomplete, Input } from 'element-ui';
import MaskedInput from 'vue-masked-input';
const Multiselect = () => import('vue-multiselect');
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';

const fileReader = new FileReader();

export default {
  inject: ['$validator'],
  components: {
    elButton: Button,
    elUpload: Upload,
    elAutocomplete: Autocomplete,
    MaskedInput,
    Multiselect
  },
  computed: {
    cityData() {
      return this.cityResponse ? this.cityResponse.map((i) => i.name) : [];
    },
    zipData() {
      return this.cityResponse
        ? sortBy(
            uniq(
              this.cityResponse.map((i) => i.postalCode),
              'postalCode'
            ),
            'postalCode'
          )
        : [];
    },
    countryData() {
      return this.countryResponse
        ? this.countryResponse.map((country) => {
            return { value: country.name, iso: country.iso };
          })
        : [];
    },
    maxLength() {
      if (this.field.maxLength && this.field.maxLength < 0) {
        this.field.maxLength *= -1;
      }
      return this.field.maxLength > 255 || !this.field.maxLength ? 255 : this.field.maxLength;
    }
  },
  data() {
    return {
      selectedUploadField: null
    };
  },
  props: {
    countryResponse: {
      type: [Object, Array],
      default: () => []
    },
    cityResponse: {
      type: [Object, Array],
      default: () => []
    },
    data: {
      required: true,
      type: [Object, Array]
    },
    field: {
      type: Object,
      required: true,
      default: {}
    },
    value: {
      required: true
    },
    name: {
      type: String
    },
    index: {
      required: true,
      type: Number
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    querySearch(queryString, cb) {
      const countryMatches = this.countryData
        ? this.countryData.filter((country) => {
            return country.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
          })
        : [];
      cb(countryMatches);
    },
    onInput($event) {
      if ($event) {
        if (typeof $event === 'string') {
          return this.$emit('input', $event);
        }
        this.$emit('input', $event.target.value);
      }
    },
    handleUploadChange(file, fileList) {
      Vue.set(this.field, 'fileList', fileList);
      fileReader.onloadend = () => {
        Vue.set(this.field, 'FileData', fileReader.result);
        Vue.set(this.field, 'FileExtension', '.' + file.name.split('.').pop());
        Vue.set(this.field, 'FileName', file.name);
        this.$forceUpdate();
      };
      fileReader.readAsDataURL(file.raw);
    },
    handleUploadRemove(file, fileList) {
      Vue.set(this.field, 'fileList', fileList);
      Vue.set(this.field, 'FileData', null);
      Vue.set(this.field, 'FileExtension', null);
      Vue.set(this.field, 'FileName', null);
      this.$forceUpdate();
    }
  }
};
</script>

<style lang="scss">
@import '~vue-multiselect/dist/vue-multiselect.min.css';

.form-input {
  .multiselect__tags input {
    box-shadow: none !important;
  }
  .el-upload__input {
    display: none !important;
  }
  .el-upload--picture {
    // display: grid !important;

    .el-upload-list__item {
      margin-top: 0 !important;
    }
  }

  .el-upload-list__item.is-ready {
    margin-top: 0;
  }

  .el-upload-list {
    @media (min-width: 772px) {
      max-width: 70%;
    }
  }

  .el-autocomplete {
    width: 100%;
  }

  input[readonly],
  textarea[readonly] {
    cursor: not-allowed;
    pointer-events: none;
  }
}
</style>
